/* runtime-monitor */
/* .runtime-monitor { */
    /* padding: 0 10px 40px 10px; */
    /* flex: 1 1 auto; */
    /* display: flex; */
    /* flex-direction: column; */
    /* height: calc(100vh - 60px - 85px); */
    /* overflow-y: scroll; */
/* } */

.runtime-monitor {
    background-color: rgb(242, 247, 248);
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1 1 auto;
}

/* content > search */
.runtime-monitor .search {
    padding: 8px;
    height: 46px;
    background-color: white;
    margin-bottom: 15px;
}
.runtime-monitor .search span {
    margin-right: 12px;
}
/* content > panel */
.runtime-monitor .panel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.runtime-monitor .panel .infoBlock{
    min-width: 280px;
    width: calc(25% - 10px);
    height: 160px;
    border-radius: 8px;
    background-color: white;
}
.runtime-monitor .panel .infoBlock .infoTitle {
    height: 36px;
    font-size: 16px;
    border-radius: 8px 8px 0 0;
    font-weight: bold;
    padding: 4px 8px;
    color: white;
}
.runtime-monitor .panel .infoBlock .infoContent {
    padding: 5px;
    height: 124px;
    overflow: hidden;
}
.runtime-monitor .panel .infoBlock .infoContent .content-left {
    width: calc(60%);
    float: left;
}
.runtime-monitor .panel .infoBlock .infoContent .content-right {
    width: calc(40%);
    float: right; 
}
.runtime-monitor .panel .infoBlock .infoContent .content-right {
    text-align: right;
    padding-right: 10px;
}
.runtime-monitor .panel .infoBlock .infoContent .content-right .chart {
    width: 160px;
    height: 160px;
    margin-top: -96px;
    margin-left: -36px;
}
/* content > flow */
.runtime-monitor .flow {
    height: 500px;
    background-color: white;
    margin-bottom: 15px;
    padding: 10px;
}
.runtime-monitor .flow .flow-title {
    font-size: 18px;
    font-weight: 500;
}
.runtime-monitor .flow .flow-content {
    width: 930px;
    height: 456px;
    margin: 0 auto;
    position: relative;
}
.runtime-monitor .flow .flow-content img {
    position: absolute;
}
.runtime-monitor .flow .flow-content>div{
    position: absolute;
}

/* content > power */
.runtime-monitor .power {
    background-color: white;
    height: 380px;
    margin-bottom: 15px;
    width: calc(100% - 330px);
    display: inline-block;
    padding: 10px;
}
.runtime-monitor .power .power-title {
    font-size: 18px;
    margin-bottom: 8px;
}
/* content > siderbar */
.runtime-monitor .siderbar {
    background-color: white;
    height: 380px;
    width: 315px;
    float: right;
}
.runtime-monitor .siderbar .notice {
    height: 130px;
    border: 1px solid #ccc;
    font-size: 14px;
}
.runtime-monitor .siderbar .notice .notice-title {
    background-color: #eee;
    font-size: 14px;
    padding: 3px;
}
.runtime-monitor .siderbar .notice .notice-content {
    padding: 5px;
    font-size: 15px;
    line-height: 18px;
}
.runtime-monitor .siderbar .revenue {
    padding: 10px;
}
.runtime-monitor .siderbar .revenue .revenue-title {
    color: #525c65;
    font-size: 20px;
    font-weight: bold;
}
.runtime-monitor .siderbar .revenue .revenue-box {
    background-color: #f5f5f5;
    height: 80px;
    border-radius: 10px;
    margin-top: 12px;
    padding: 5px 20px;
}
.runtime-monitor .siderbar .revenue .revenue-box img {
    float: left;
    margin-right: 20px;
}
.runtime-monitor .siderbar .revenue .box-num {
    float: left;
    margin-top: 8px;
    color: #525c65;
}
.runtime-monitor .siderbar .revenue .box-num span:first-child {
    font-size: 20px;
    font-weight: bold;
}
/* content > energy */
.runtime-monitor .energy {
    background-color: white;
    /* height: 400px; */
    margin-bottom: 15px;
    padding: 10px;
    flex: 1 1 auto;
}
.runtime-monitor .energy .energy-title {
    font-size: 18px;
    float: left;
}
.runtime-monitor .energy .energy-date {
    float: right;
    margin-left: 40px;
}
.runtime-monitor .energy .energy-btn {
    float: right;
}
.runtime-monitor .energy .energy-chart {
    margin-top: 50px;
    clear: both;
}

