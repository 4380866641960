/* .inv-setting */
.inv-setting {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inv-setting-header {
    flex: none;
    background-color: white;
    padding: 8px 5px;
    margin-bottom: 8px;
}
.inv-setting-header span {
    margin-right: 10px;
}

.inv-setting-main {
    flex: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
}